<style lang="less" scoped>
  @import "../../../assets/css/variables";

  #main {
    margin: 50px auto 0;
    width:70%;
    min-height: 400px;
  }
  .no-data {
    color: #666;
    text-align: center;
    margin: 25px auto;
  }
  .uperForm {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 100;
  }
  
</style>

<template :is="view"
          transition="fade"
          transition-mode="out-in">

  <div class="page-wrapper" style="position: relative;">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>设备分布图</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container" id="map-wraper" style="overflow:hidden; padding:0;">
      <el-form class="uperForm" :inline="true">
          <el-form-item>
            <el-select 
              v-model="deviceId"
              filterable
              remote
              clearable
              :remote-method="queryDevices"
              placeholder="设备类型">
              <el-option
                v-for="item in dvcTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="initData">查询</el-button>
          </el-form-item>
        </el-form>
      <div id="main" style="width: 1100px;height: 600px;margin: 0 auto;"></div>
    </div>
    

    
  </div>
</template>

<script>

  import {searchDevicesByType, searchProvinceListByDeviceKey, getDeviceLastValue, getDeviceById, getDvcLayout} from "../../../services/device";
  import {cities} from "../../../services/cities"
  // import echarts from "echarts";
  //import china from '../../../services/china';
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment';
  let dx = 0,dy = 0;
  export default {
    data() {
      return {
        deviceId: '',
        dvcTypeList: [],
        cityList: [],
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
        geoCoordMap: cities,
        chartOption: {
              tooltip : {
                trigger: 'item',
                // position: function(point){
                //   console.log(point)
                  
                //   return [point[0]+dx, point[1]+dy]
                //   // return [0, 0]
                // },
                formatter: function (params) {
                  return params.name + ' : ' + params.value[2];
                }
              },
              bmap: {
                center: [104.114129, 37.550339],
                zoom: 5,
                roam: true,
                mapStyle: {
                  styleJson: [{
                    'featureType': 'water',
                    'elementType': 'all',
                    'stylers': {
                        'color': '#d1d1d1'
                    }
                  }, 
                  {
                      'featureType': 'land',
                      'elementType': 'all',
                      'stylers': {
                          'color': '#f3f3f3'
                      }
                  }, 
                  {
                      'featureType': 'railway',
                      'elementType': 'all',
                      'stylers': {
                          'visibility': 'off'
                      }
                  }, 
                  {
                      'featureType': 'highway',
                      'elementType': 'all',
                      'stylers': {
                          'color': '#fdfdfd'
                      }
                  }, 
                  {
                      'featureType': 'highway',
                      'elementType': 'labels',
                      'stylers': {
                          'visibility': 'off'
                      }
                  }, 
                  {
                      'featureType': 'arterial',
                      'elementType': 'geometry',
                      'stylers': {
                          'color': '#fefefe'
                      }
                  }, 
                  {
                      'featureType': 'arterial',
                      'elementType': 'geometry.fill',
                      'stylers': {
                          'color': '#fefefe'
                      }
                  }, 
                  {
                      'featureType': 'poi',
                      'elementType': 'all',
                      'stylers': {
                          'visibility': 'off'
                      }
                  }, 
                  {
                      'featureType': 'green',
                      'elementType': 'all',
                      'stylers': {
                          'visibility': 'off'
                      }
                  }, 
                  {
                      'featureType': 'subway',
                      'elementType': 'all',
                      'stylers': {
                          'visibility': 'off'
                      }
                  }, 
                  {
                      'featureType': 'manmade',
                      'elementType': 'all',
                      'stylers': {
                          'color': '#d1d1d1'
                      }
                  }, 
                  {
                      'featureType': 'local',
                      'elementType': 'all',
                      'stylers': {
                          'color': '#d1d1d1'
                      }
                  }, 
                  {
                      'featureType': 'arterial',
                      'elementType': 'labels',
                      'stylers': {
                          'visibility': 'off'
                      }
                  }, 
                  {
                      'featureType': 'boundary',
                      'elementType': 'all',
                      'stylers': {
                          'color': '#fefefe'
                      }
                  }, 
                  {
                      'featureType': 'building',
                      'elementType': 'all',
                      'stylers': {
                          'color': '#d1d1d1'
                      }
                  }, 
                  {
                      'featureType': 'label',
                      'elementType': 'labels.text.fill',
                      'stylers': {
                          'color': '#999999'
                      }
                  }]
                }
              },
              series : [
                {
                  name: 'sale',
                  type: 'scatter',
                  coordinateSystem: 'bmap',
                  data: [],
                  symbolSize: function (val) {
                    return val[2] / 10;
                  },
                  label: {
                    normal: {
                      formatter: '{b}',
                      position: 'right',
                      show: false
                    },
                    emphasis: {
                      show: true
                    }
                  },
                  itemStyle: {
                    normal: {
                      color: '#01ACCE'
                      //color: 'red'
                    }
                  }
                },
                {
                  name: 'Top 5',
                  type: 'effectScatter',
                  coordinateSystem: 'bmap',
                  // data: convertData(data.sort(function (a, b) {
                  //   return b.value - a.value;
                  // }).slice(0, 6)),
                  data: [],
                  symbolSize: function (val) {
                    return val[2] / 10;
                  },
                  animationThreshold: 0,
                  showEffectOn: 'render',
                  rippleEffect: {
                    brushType: 'stroke'
                  },
                  hoverAnimation: true,
                  label: {
                    normal: {
                      formatter: '{b}',
                      position: 'right',
                      show: true
                    }
                  },
                  itemStyle: {
                    normal: {
                      color: '#01ACCE',
                      shadowBlur: 10,
                      shadowColor: 'rgb(106, 225, 188)'
                    }
                  },
                  zlevel: 1
                }
              ]
            },
      }
    },
    methods: {
      /**
       * 获取所有的设备
       */
      async queryDevices(key){
        const searchParam = {key: key}
        const responseData = await searchDevicesByType(searchParam);
        if (responseData.errorCode === 0) {
          this.dvcTypeList = responseData && responseData.data && responseData.data.datas || [];
          this.total = responseData.data.totalCount;
        }
        this.loaded = true;
      },
      /**
       * 数据转换
       */
      convertData(data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = this.geoCoordMap[data[i].name];
          if (geoCoord) {
              res.push({
                  name: data[i].name,
                  value: geoCoord.concat(data[i].value)
              });
          }
        }
        return res;
      },
      /**
       * 初始化地数据
       */
      async initData() {
        let arr = [];
        this.chartOption.series[0].data = [];
        this.chartOption.series[1].data = [];
        if (this.deviceId === '') this.deviceId = null;
        const res = await getDvcLayout(this.deviceId);
        if (res && res.errorCode ===0 && res.data.length > 0) {
          res.data.forEach((item) => {
            if (/市/.test(item.cityName)) {
              item.cityName = item.cityName.replace(/市$/, '');
            }
            arr.push({
              name: item.cityName,
              value: item.total
            })
          })
          arr.sort((a, b) => {
            return b.value - a.value;
          })
          //console.log(arr)
          if (arr.length > 10 ) {
            this.chartOption.series[1].data = 
              this.convertData(arr.slice(0, 5));
          }
          if (arr[0].value < 100) {
            this.chartOption.series[0].symbolSize = (val) => {
              return 8;
            }; 
          } else {
            this.chartOption.series[0].symbolSize = (val) => {
              return val[2] / 10;
            }
          }
          this.chartOption.series[0].data = this.convertData(arr);
          //console.log(this.chartOption.series[0].data)
        } else {
          this.chartOption.series[0].data = [];
          this.chartOption.series[1].data = [];
        }
        this.initEchart();
       
        //this.chartOption.series[0].data = this.convertData(data);
      },
      /**
       * 初始化地图
       */
      initEchart() {
        var that = this;
        let myChart = echarts.init(document.getElementById('main'));
        //console.log(this.chartOption)
        myChart.setOption(this.chartOption);
        //myChart.on('mouseover', this.evMouseOver);
        // let bmap = myChart.getModel().getComponent('bmap').getBMap();
        // bmap.addEventListener("dragstart", dragstart)
        // bmap.addEventListener("dragend",function(ev){
        //   //console.log(ev.pixel)
        //   this.endY = ev.pixel.y;
        //   this.endX = ev.pixel.x;
        //   dx = this.endX - this.startX;
        //   dy = this.endY - this.startY;
        // });
        // function dragstart(ev) {
        //   console.log(111111)
        //   this.startY = ev.pixel.y;
        //   this.startX = ev.pixel.x;
        //   bmap.removeEventListener("dragstart", dragstart)
        // }
      },

      evMouseOver(params) {
        const x = params.event.offsetX;
        const y = params.event.offsetY;
        // console.log("offsetx: "+x);
        // console.log("offsety: "+y);
      },

      formatTime(time){
        return time ? moment(time).format('YYYY-MM-DD HH:mm:ss') : '';
      },
      
    },
    computed: {
      ...mapGetters(
        {user: 'userInfo'}
      )
    },
    created() {
      // const obj = document.getElementById('map-wraper');
      // console.log(obj)
    },
    async mounted(){
      const obj = document.getElementById('map-wraper');
      const main = document.getElementById('main');
      //console.log(obj)
      this.queryDevices();
      const width = obj.clientWidth;
      const height = obj.clientHeight;
      //console.log(width)
      //console.log(height)
      main.style.width = width + 20 + 'px';
      if (width > 1200) {
        main.style.height = height + 'px';
      } else {
        main.style.height = height - 60 + 'px';
      }
      this.initData();
     
      
    }
  }

</script>
