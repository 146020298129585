export const cities = {
	"北京": [116.46, 39.92],
	"上海": [121.48, 31.22],
	"天津": [117.2, 39.13],
	"重庆": [106.54, 29.59],
	"石家庄":[114.48,38.03],
	"唐山":[118.02,39.63],
	"秦皇岛":[119.57,39.95],
	"邯郸":[114.47,36.6],
	"邢台":[114.48,37.05],
	"保定":[115.48,38.85],
	"张家口":[114.87,40.82],
	"承德":[117.93,40.97],
	"沧州":[116.83,38.33],
	"廊坊":[116.7,39.53],
	"衡水":[115.72,37.72],
	"太原":[112.53,37.87],
	"大同":[113.3,40.12],
	"阳泉":[113.57,37.85],
	"长治":[113.08,36.18],
	"晋城":[112.83,35.52],
	"临汾":[111.5,36.08],
	"运城":[110.97,35.03],
	"呼和浩特":[111.65,40.82],
	"包头":[110,40.58],
	"乌海":[106.82,39.67],
	"赤峰":[118.87,42.28],
	"通辽":[122.28,43.63],
	"沈阳":[123.38,41.8],
	"大连":[121.62,38.92],
	"鞍山":[122.85,41.12],
	"抚顺":[123.97,41.97],
	"本溪":[123.73,41.3],
	"丹东":[124.37,40.13],
	"锦州":[121.15,41.13],
	"营口":[122.18,40.65],
	"阜新":[121.65,42],
	"辽阳":[123.17,41.28],
	"铁岭":[123.85,42.32],
	"朝阳":[120.42,41.58],
	"长春":[125.35,43.88],
	"吉林":[126.57,43.87],
	"四平":[124.37,43.17],
	"辽源":[125.15,42.97],
	"通化":[125.92,41.49],
	"白城":[122.82,45.63],
	"哈尔滨":[126.63,45.75],
	"齐齐哈尔":[123.97,47.33],
	"牡丹江":[129.58,44.6],
	"佳木斯":[130.35,46.83],
	"大庆":[125.03,46.58],
	"伊春":[128.92,47.73],
	"鹤岗":[130.3,47.33],
	"双鸭山":[131.17,46.65],
	"七台河":[130.83,45.82],
	"绥化":[127,46.63],
	"黑河":[127.53,50.22],
	"南京":[118.78,32.04],
	"无锡":[120.29,31.59],
	"徐州":[117.2,34.26],
	"常州":[119.95,31.79],
	"苏州":[120.62,31.32],
	"南通":[121.05,32.08],
	"连云港":[119.16,34.59],
	"淮安":[119.15,33.5],
	"盐城":[120.13,33.38],
	"扬州":[119.42,32.39],
	"镇江":[119.44,32.2],
	"泰州":[119.9,32.49],
	"宿迁":[118.3,33.96],
	"杭州":[120.19,30.26],
	"宁波":[121.56,29.86],
	"温州":[120.65,28.01],
	"绍兴":[120.58,30.01],
	"湖州":[120.1,30.86],
	"嘉兴":[120.76,30.77],
	"金华":[119.64,29.12],
	"衢州":[118.88,28.97],
	"丽水":[119.92,28.45],
	"合肥":[117.27,31.86],
	"芜湖":[118.38,31.33],
	"蚌埠":[117.34,32.93],
	"淮南":[116.98,32.62],
	"马鞍山":[118.48,31.56],
	"淮北":[116.77,33.97],
	"铜陵":[117.82,30.93],
	"安庆":[117.03,30.52],
	"阜阳":[115.81,32.89],
	"宿州":[116.97,33.63],
	"滁州":[118.31,32.33],
	"六安":[116.49,31.73],
	"宣城":[118.73,31.95],
	"福州":[119.3,26.08],
	"莆田":[119,25.44],
	"泉州":[118.58,24.93],
	"厦门":[118.1,24.46],
	"漳州":[117.35,24.52],
	"龙岩":[117.01,25.12],
	"三明":[117.61,26.23],
	"南平":[118.11,27.34],
	"宁德":[119.52,26.65],
	"南昌":[115.89,28.68],
	"赣州":[114.92,25.85],
	"宜春":[114.38,27.81],
	"吉安":[114.97,27.12],
	"上饶":[117.97,28.47],
	"抚州":[116.34,28],
	"九江":[115.97,29.71],
	"景德镇":[117.22,29.3],
	"萍乡":[113.85,27.6],
	"新余":[114.92,27.81],
	"鹰潭":[117.02,28.23],
	"济南":[117,36.65],
	"青岛":[120.33,36.07],
	"淄博":[118.05,36.78],
	"枣庄":[117.57,34.86],
	"东营":[118.49,37.46],
	"烟台":[121.39,37.52],
	"潍坊":[119.1,36.62],
	"济宁":[116.59,35.38],
	"泰安":[117.13,36.18],
	"威海":[122.1,37.5],
	"日照":[119.46,35.42],
	"滨州":[118.03,37.36],
	"德州":[116.29,37.45],
	"聊城":[115.97,36.45],
	"临沂":[118.35,35.05],
	"莱芜":[117.67,36.19],
	"郑州":[113.65,34.76],
	"开封":[114.35,34.79],
	"洛阳":[112.44,34.7],
	"平顶山":[113.29,33.75],
	"安阳":[114.35,36.1],
	"鹤壁":[114.17,35.9],
	"新乡":[113.85,35.31],
	"焦作":[113.21,35.24],
	"濮阳":[114.98,35.71],
	"许昌":[113.81,34.02],
	"漯河":[114.02,33.56],
	"三门峡":[111.19,34.76],
	"商丘":[115.65,34.44],
	"周口":[114.63,33.63],
	"驻马店":[114.02,32.98],
	"南阳":[112.53,33.01],
	"信阳":[114.08,32.13],
	"武汉":[114.31,30.52],
	"黄石":[115.09,30.2],
	"十堰":[110.79,32.65],
	"宜昌":[111.3,30.7],
	"荆门":[112.19,31.02],
	"黄冈":[114.87,30.44],
	"孝感":[113.91,31.92],
	"咸宁":[114.28,29.87],
	"长沙":[113,28.21],
	"株洲":[113.16,27.83],
	"湘潭":[112.91,27.87],
	"衡阳":[112.39,26.98],
	"邵阳":[111.5,27.22],
	"岳阳":[113.09,29.37],
	"益阳":[112.33,28.6],
	"常德":[111.69,29.05],
	"娄底":[111.96,27.71],
	"郴州":[113,25.79],
	"永州":[111.63,26.22],
	"怀化":[109.95,27.52],
	"广州":[113.23,23.16],
	"深圳":[114.07,22.62],
	"珠海":[113.52,22.3],
	"汕头":[116.69,23.39],
	"佛山":[113.11,23.05],
	"韶关":[113.62,24.84],
	"肇庆":[112.44,23.05],
	"江门":[113.06,22.61],
	"茂名":[110.88,21.68],
	"惠州":[114.4,23.09],
	"云浮":[112.02,22.93],
	"河源":[114.68,23.73],
	"阳江":[111.95,21.85],
	"清远":[113.01,23.7],
	"东莞":[113.75,23.04],
	"中山":[113.38,22.52],
	"潮州":[116.63,23.68],
	"揭阳":[116.35,23.55],
	"南宁":[108.33,22.84],
	"柳州":[109.4,24.33],
	"桂林":[110.28,25.29],
	"梧州":[111.34,23.51],
	"北海":[109.12,21.49],
	"崇左":[107.37,22.42],
	"来宾":[109.24,23.76],
	"玉林":[110.14,22.64],
	"百色":[106.62,23.91],
	"河池":[108.06,24.7],
	"钦州":[108.61,21.96],
	"海口":[110.35,20.02],
	"成都":[104.06,30.67],
	"绵阳":[104.73,31.48],
	"泸州":[105.39,28.91],
	"德阳":[104.37,31.13],
	"遂宁":[105.58,30.52],
	"内江":[105.04,29.59],
	"资阳":[104.6,30.19],
	"宜宾":[104.56,29.77],
	"雅安":[102.97,29.97],
	"广安":[106.61,30.48],
	"巴中":[106.73,31.86],
	"贵阳":[106.71,26.57],
	"六盘水":[104.82,26.58],
	"遵义":[106.9,27.7],
	"铜仁":[109.21,27.73],
	"毕节":[105.29,27.32],
	"安顺":[105.92,26.25],
	"昆明":[102.73,25.04],
	"昭通":[103.7,29.32],
	"曲靖":[103.79,25.51],
	"玉溪":[102.52,24.35],
	"普洱":[101.03,23.07],
	"丽江":[100.25,26.86],
	"拉萨":[91.11,29.97],
	"日喀则":[88.82,29.28],
	"林芝":[94.25,29.59],
	"西安":[108.95,34.27],
	"铜川":[109.11,35.09],
	"宝鸡":[107.15,34.38],
	"咸阳":[108.72,34.36],
	"渭南":[109.5,34.52],
	"安康":[109.02,32.7],
	"延安":[109.47,36.6],
	"榆林":[109.77,38.3],
	"兰州":[103.73,36.03],
	"天水":[105.69,34.6],
	"酒泉":[98.5,39.71],
	"张掖":[100.46,38.93],
	"武威":[102.61,37.94],
	"定西":[104.57,35.57],
	"平凉":[106.68,35.51],
	"庆阳":[107.88,36.03],
	"西宁":[101.74,36.56],
	"银川":[106.27,38.47],
	"石嘴山":[106.39,39.04],
	"吴忠":[106.21,37.99],
	"固原":[106.28,36.01],
	"中卫":[105.18,37.51],
	"乌鲁木齐":[87.68,43.77],
	"克拉玛依":[84.77,45.59],
	"吐鲁番":[89.19,42.91],
	"哈密":[93.44,42.78],
	"鄂尔多斯":[109.78,39.60],
	"盘锦":[122.07,41.11],
	"葫芦岛":[120.83,40.71],
	"台州":[121.42,28.65],
	"舟山":[122.20,29.98],
	"菏泽":[115.48,35.2],
	"荆州":[112.23,30.33],
	"张家界":[110.47,29.11],
	"湛江":[110.35,21.27],
	"汕尾":[115.37,22.78],
	"三亚":[109.51,18.25],
	"自贡":[104.77,29.3],
	"攀枝花":[101.71,26.58],
	"南充":[106.11,30.83],
	"嘉峪关":[98.28,39.7],
	"金昌":[102.18,38.52]
}